import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import HamburgerMenu from "./HamburgerMenu";
import {
    Grid,
  } from "@mui/material";

const Header = () => {
  return (
    <AppBar
      position="static"
      sx={{
        background: "white", // Set the background color to white
        boxShadow: 'none',  // Remove the shadow
        borderBottom: "1px solid black",
      }}
    >
      <Toolbar style={{position:'relative'}}>
        <Grid style = {{paddingTop:'1rem', paddingBottom:'1rem'}}>
          <img src="/images/cesar_logo.png" alt='cesar_logo' height="48" />
        </Grid>
        <HamburgerMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
