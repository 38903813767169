import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Home from "./Home";
import Holdings from "./Holdings";
import Footer from "./Footer";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { inject } from '@vercel/analytics';
 
inject();

function App() {
  return (
    <Router>
      <div>
        <ThemeProvider theme={theme}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/holdings" element={<Holdings />} />
          </Routes>
          {/* Footer */}
          <Footer />
        </ThemeProvider>
      </div>
    </Router>
  );
}

export default App;
