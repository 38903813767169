import React from "react";
import { Grid, Paper, Chip, Typography } from "@mui/material";
import { styled } from "@mui/system";

const properties = [
  {
    id: 1,
    streetAddress: "463 River Rd",
    city: "Sault Ste Marie",
    country: "Canada",
  },
  {
    id: 2,
    streetAddress: "512 Allenside Rd",
    city: "Sault Ste Marie",
    country: "Canada",
  },
  {
    id: 3,
    streetAddress: "506 Allenside Rd",
    city: "Sault Ste Marie",
    country: "Canada",
  },
  {
    id: 4,
    streetAddress: "628 Second Line",
    city: "Sault Ste Marie",
    country: "Canada",
  },
  {
    id: 5,
    streetAddress: "237 Bruce Street",
    city: "Sault Ste Marie",
    country: "Canada",
  },
  {
    id: 6,
    streetAddress: "38 Ashgrove Ave",
    city: "Sault Ste Marie",
    country: "Canada",
  },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color:'#0072e5',
  backgroundColor:'#f0f7ff',
  borderColor:'#e5eaf2',
  borderWidth:'1px',
  borderStyle:'solid',
  marginBottom:'8px',
}));

const PropertyCards = () => {
  return (
    <Grid container spacing={2} pt={2} pb={4}>
      {properties.map((property) => (
        <Grid item xs={12} md={6} key={property.id}>
          <StyledPaper elevation={3}>
            <StyledChip label={property.country} color="primary" size="small" />
            <Typography variant="h4" component="div">
              {property.streetAddress}
            </Typography>
            <Typography variant="overline">Sault Ste Marie</Typography>
          </StyledPaper>
        </Grid>
      ))}
    </Grid>
  );
};

export default PropertyCards;
