import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { mdilEmail, mdilBank } from "@mdi/light-js";
import { Button, Grid, Typography, Container } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";

const HomeContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(12), // Adjust the top padding as needed
  [theme.breakpoints.up("sm")]: {
    paddingTop: theme.spacing(0), // Reset the top padding for screens wider than 'sm'
  },
}));

const handleEmailClick = () => {
  // Replace 'your-email@example.com' with your actual email address
  const email = "anthony.cesario@cesarltd.ca";
  window.location.href = `mailto:${email}`;
};

const Home = () => {
  return (
    <HomeContainer>
      {/* defaults to negative margin here */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={0}
        style={{ minHeight: "calc(100vh)", marginTop: "-64px" }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="h2" gutterBottom>
            A Modern Approach to Real Estate
          </Typography>
          <Button
            to='/holdings'
            component={RouterLink}
            variant="contained"
            color="primary"
            size="large"
            endIcon={
              <SvgIcon>
                <path d={mdilBank} />
              </SvgIcon>
            }
            style={{ marginRight: "16px", marginBottom: "8px" }}
          >
            See Properties
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            endIcon={
              <SvgIcon>
                <path d={mdilEmail} />
              </SvgIcon>
            }
            onClick={handleEmailClick}
            style={{ marginBottom: "8px" }}
          >
            Get In Touch
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            justifyContent: "center",
            display: "flex",
            paddingLeft: "48px",
            paddingRight: "48px",
          }}
        >
          <img src={"images/buildings.png"} alt='buildings' style={{ width: "100%" }} />
        </Grid>
      </Grid>
    </HomeContainer>
  );
};

export default Home;
