import React from "react";
import {  Divider, Grid, Container, Link, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const linkedInProfileURL = "https://www.linkedin.com/company/cesario-development-group/";

const Footer = () => {
  return (
    <>
      <Container>
        <Divider />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ paddingTop: "48px", paddingBottom: "48px" }}
        >
          {/* Row 1: Logo */}
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <img src="/images/cesar_logo.png" alt="Logo" height="64" />
          </Grid>

          {/* Row 2: Links */}

          {/* Row 3: LinkedIn Icon */}
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <Link
              href={linkedInProfileURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </Link>
          </Grid>

          {/* Row 4: Copyright Text */}
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <Typography variant="body2" style={{color:"#5A5A5A"}}>© 2023 Cesar Ltd</Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Footer;
