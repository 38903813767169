import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Set the primary color to blue 4d6ca2, #035e95
    },
    secondary: {
      main: "#000000", // Set the secondary color to green #de6844,#fd4e00
    },
  },
  typography: {
    fontFamily: "Space Grotesk, sans-serif", // Body font
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderTop: `1px solid`,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "Space Grotesk, sans-serif", // Body font
          color: "black", // Set the color to white
          textDecoration: "none", // Remove the underline
          "&:hover": {
            textDecoration: "none", // Remove the underline on hover
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          borderRadius: "50px",
          // Add any other style overrides for primary contained buttons here
        },
        containedSecondary: {
            borderRadius: "50px",
            // Add any other style overrides for primary contained buttons here
          },
      },
    },
  },
});

export default theme;
