import React from "react";
import {
  Grid,
  Typography,
  Container,
} from "@mui/material";
import PropertyCards from "./PropertyCards";

const Holdings = () => {
  return (
    <Container>
      {/* defaults to negative margin here */}
      <Grid
        container
        justifyContent="center"
        spacing={0}
        style={{ minHeight: "calc(100vh)", paddingTop:'24px' }}
      >
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Real Estate Holdings
          </Typography>
          <PropertyCards />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Holdings;
